
<template>
  <div>

    <Table
      stripe
      size="small"
      :data="taskList"
      :columns="tableColumns"
    ></Table>

    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber" @on-change="handlePageNumberChange"></Page>
    </div>

  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getInvoiceApplicationsPage } from '@/api/scp/invoiceapplication'
export default {
  props: {
    showAllTask: Boolean,
    keyword: String,
    applyDate: String
  },
  data () {
    return {
      taskList: [],
      tableColumns: [
        {
          title: '开票类型',
          key: 'typeName',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.typeName + (params.row.content ? '[' + params.row.content + ']' : '')
              )
            ])
          }
        },
        {
          title: '开票金额(含税)',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'strong',
                {
                  attrs: {
                    style: 'color:#44bd32'
                  }
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        { title: '合同/结算单编号', key: 'code' },
        { title: '合同/结算单名称', key: 'name' },
        {
          title: '发起人',
          key: 'applyUserName'
        },
        {
          title: '发起时间',
          key: 'createTime'
        },
        {
          title: '状态',
          key: 'statusName',
          render: (h, params) => {
            return this.formaTaskStautsColor(h, params.row.status, params.row.statusName)
            // const color = this.formaTaskStautsColor(params.row.status)
            // return h('div', [
            //   h(
            //     'Tag',
            //     {
            //       props: {
            //         color: color
            //       }

            //     },
            //     params.row.statusName
            //   )
            // ])
          }
        },
        {
          title: '备注',
          key: 'statusName',
          render: (h, params) => {
            return h('div', [
              params.row.status === -1 ? h(
                'span',
                params.row.invalidMark ? params.row.invalidMark : '-'
              ) : h(
                'span',
                params.row.reason ? params.row.reason : '-'
              )
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleEdit(params.row.bid)
                    }
                  }
                }, '详情'
              )
            ])
          }
        }
      ],

      total: 0,
      query: {
        statusList: this.showAllTask ? null : JSON.stringify([0, 1]),
        pageNumber: 1,
        pageSize: 15
      }
    }
  },
  created () {
    this.initPageData()
  },

  methods: {
    formaTaskStautsColor (h, status, showName) {
      let color = 'Gray'
      let border = false
      if (status === 0) { // 申请总
        return h('span', showName)
      } else if (status === 1) { // 部分完成
        color = '#a59426'
      } else if (status === 2) { // 已完成
        color = '#141414'
        border = true
      } else if (status === 5) { // 已通过
        color = '#44bd32'
      } else if (status === -1) { // 已作废
        color = '#3B3B3B'
      }

      return h('Tag', { props: { color }, style: { border: border ? '1px solid #fff' : 'none' } }, showName)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleEdit (id) {
      this.$emit('on-showDetail', id)
    },
    initPageData () {
      getInvoiceApplicationsPage(this.query).then(res => {
        this.taskList = res.list
        this.total = res.totalRow
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    changeTaskType (id) {
      this.currentTypeId = id
    },
    reloadPage () {
      this.query.pageNumber = 1
      this.taskList = []
      this.initPageData()
    }
  },
  watch: {
    showAllTask (val) {
      this.query.statusList = val ? null : JSON.stringify([0, 1])
      this.reloadPage()
    },
    keyword (val) {
      this.query.keyword = val
      this.reloadPage()
    },
    applyDate (val) {
      this.query.applyDate = val
      this.reloadPage()
    }
  }
}
</script>
